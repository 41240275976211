import type { ReactElement } from 'react';
import { Slide, ToastContainer } from 'react-toastify';

export function Toaster(): ReactElement {
	return (
		<ToastContainer
			pauseOnHover
			closeOnClick
			theme="dark"
			autoClose={3000}
			position="bottom-right"
			hideProgressBar={true}
			pauseOnFocusLoss={false}
			transition={Slide}
			draggable={false}
		/>
	);
}
