import '@src/styles/globals.scss';

import { QueryProvider } from '@src/lib/react-query/query-provider';
import { Toaster } from '@src/modules/shared/components/toaster';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import type { ReactElement } from 'react';
import React, { Fragment } from 'react';

if (typeof window === 'undefined') {
	React.useLayoutEffect = () => null;
}

export default function App({ Component, pageProps }: AppProps): ReactElement {
	return (
		<Fragment>
			<Head>
				<title>Mann+Hummel Catalog Store</title>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<link rel="icon" href="/favicon-32x32.png" />
			</Head>
			<QueryProvider>
				<Component {...pageProps} />
				<Toaster />
			</QueryProvider>
		</Fragment>
	);
}
